import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn from '../../layouts/TemplateColumn';
import { PageHeader, PageIntro, PageSection, IconDefinitionList, RelatedContent, PrimaryCallOut } from '../../components';
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: ``,
    keywords: ``,
    description: ``
}

const jobCode = jobCodes[31].jobCode;

const relatedContent = [
    {
        section: 'RESOURCES',
        link: {
            url: '/resources/hcp-and-patient-resources/',
            label: 'HCP & Patient Resources'
        }
    },
    {
        section: 'RESOURCES',
        link: {
            url: '/resources/key-you-patient-support-program/',
            label: 'Patient Support Program'
        }
    },
    {
        section: 'RESOURCES',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action '
        }
    },
]

const PSASteps = [
    {
        iconColumn: {
            icon: '/static/images/badge-bookmark.png',
            heading: 'Billing & Coding',
            alt: ''
        },
        contentMarkup: `<p>Merck Access Program can provide helpful resources when billing for KEYTRUDA:</p>
                        <ul>
                            <li>Healthcare Common Procedure Coding System (HCPCS) codes</li>
                            <li>Revenue codes</li>
                            <li>Current procedural terminology codes for administration</li>
                            <li>ICD-10-CM diagnosis codes</li>
                        </ul>
                        `,
    },
    {
        iconColumn: {
            icon: '/static/images/badge-info2.png',
            heading: 'Coverage & Reimbursement Information',
            alt: ""
        },
        contentMarkup: `<p>The Merck Access Program can contact insurers to obtain coverage and benefits information for KEYTRUDA (pembrolizumab). You can also download resources, including:</p>
                        <ul>
                            <li>Checklists for prior authorization or appeals</li>
                            <li>Sample prior authorization letters</li>
                            <li>Sample CMS-1500 and UB-04 forms</li>
                        </ul>`,
    },
    {
        iconColumn: {
            icon: '/static/images/badge-wallet.png',
            heading: 'Co-pay Assistance Program',
            alt: ""
        },
        contentMarkup: `<p>The Merck Access Program offers assistance to eligible, privately insured patients who need help affording the out-of-pocket costs for KEYTRUDA for their treatment. Terms and Conditions apply. Please see the full Terms and Conditions on MAP website.</p>
                        `,
    },
    {
        iconColumn: {
            icon: '/static/images/badge-person.png',
            heading: 'Patient Assistance Program',
            alt: ""
        },
        contentMarkup: `<p>Patients who do not have insurance or whose insurance does not cover KEYTRUDA may be eligible for free product from the Merck Patients Assistance Program, if they meet certain financial and medical criteria.</p>`,
    },
    {
        iconColumn: {
            icon: '/static/images/badge-iv.png',
            heading: 'Product Distribution',
            alt: ""
        },
        contentMarkup: `<p>The Merck Access Program can provide with information about authorized distributors of KEYTRUDA, such as:</p>
        <ul>
            <li>Contact information</li>
            <li>Order numbers for one single-use vial (carton)</li>
            <li>Order numbers for two single-use vials (carton)</li>
        </ul>`,
    },
]


const Page = ({ location }) => {

    return (
        <DefaultLayout pageMeta={pageMeta} location={location} jobCode={jobCode}>
            <TemplateColumn id="template-c-intro">
                    <PageHeader title="Access & Reimbursement Support" />
                    <PageSection bgColor="cloud" title="The Merck Access Program for KEYTRUDA" centerTitle={true}>
                        <PageIntro centered={true}>
                            <p>The Merck Access Program can help answer questions about billing and coding, coverage and reimbursement information, co-pay assistance for eligible patients, access to free product for eligible patients, and product distribution. Call 855-257-3932 Monday through Friday, 8am to 8pm EST to speak with a Merck Access Program representative or visit the Merck Access Program.</p>
                        </PageIntro>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                {/* Begin Main Column */}
                <TemplateColumn id="template-c-main">
                    <PageSection title="Here to Assist Your Patients">
                        <IconDefinitionList data={PSASteps} theme="light"/>
                    </PageSection>
                    
                    <PageSection bgColor="gradient">
                        <PrimaryCallOut
                            title="Call or visit the Merck Access Program"
                            content="Call 855-257-3932 Monday through Friday, 8am to 8pm EST to speak with a Merck Access Program representative. A representative may be able to research your patient’s insurance benefits or out-of-pocket costs, answer questions about the enrollment form, and more."
                            primaryAction={{
                                label: 'Learn About The Merck Access Program',
                                url: 'https://www.merckaccessprogram-keytruda.com/',
                                external: true
                            }}
                        />
                    </PageSection>

                    <PageSection  bgColor='keytrudaGreen'>
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
                {/* End Main Column */}
        </DefaultLayout>
    );
};

export default Page;
